import React from "react";
import tw from "twin.macro";
import {css} from "styled-components/macro"; //eslint-disable-line
import {SectionHeading, Subheading as SubheadingBase} from "components/misc/Headings";
import {Container as ContainerBase, ContentWithPaddingXl} from "components/misc/Layouts";
import {SectionDescription} from "components/misc/Typography";
import data from '../../data'

const Container = tw(ContainerBase)`my-8 lg:my-10 bg-primary-900 text-gray-100 -mx-8 px-8`;
const HeadingContainer = tw.div``;
const Heading = tw(SectionHeading)`sm:text-3xl md:text-4xl lg:text-5xl`;
const Subheading = tw(SubheadingBase)`text-gray-100 text-center`;
const Description = tw(SectionDescription)`text-gray-100 text-center mx-auto max-w-screen-md`;

const StatsContainer = tw.div`mt-8 flex flex-col sm:flex-row items-center justify-center flex-wrap max-w-screen-lg justify-between mx-auto`
const Stat = tw.div`flex flex-col text-center p-4 tracking-wide`
const StatKey = tw.div`text-xl font-medium`
const StatValue = tw.div`text-4xl sm:text-3xl md:text-4xl lg:text-5xl font-black`

export default () => (
    <Container>
        <ContentWithPaddingXl>
            <HeadingContainer>
                <Subheading>{data.content.stats.subheading}</Subheading>
                <Heading>{data.content.stats.heading}</Heading>
                <Description>{data.content.stats.description}</Description>
            </HeadingContainer>
            <StatsContainer>
                {data.content.stats.stats.map((stat, index) => (
                    <Stat key={index}>
                        <StatValue>{stat.value}</StatValue>
                        <StatKey>{stat.key}</StatKey>
                    </Stat>
                ))}
            </StatsContainer>
        </ContentWithPaddingXl>
    </Container>
);
