import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import { css } from "styled-components/macro"; //eslint-disable-line
import React from 'react';
import Page from './pages/DefaultLandingPage'

function App() {
  return (
    <React.Fragment>
      <Page />
    </React.Fragment>
  );
}

export default App;
