import serverRedundancyIllustrationImageSrc from "images/server-redundancy-illustration.svg"
import serverFeaturesImageSrc from "images/features_overview.svg"
import serverUpgradeImageSrc from "images/upgrade.svg"
import serverStatusImageSrc from "images/server_status2.svg"
import serverTroubleshootingImageSrc from "images/troubleshooting.svg"

export default {
    name: "Goodbytes",
    primaryButtonText: "Contact Us",
    primaryButtonUrl: "#contact",
    leading: "Openfire, Designed & Developed by Professionals.",
    description: "Goodbytes specializes in Openfire development and integration challenges, and provides installation, configuration, and maintenance services.",
    introduction: "As long-time members of the core development team of Openfire and related projects, we have intimate knowledge of the Openfire code-base and have completed many open-source as well as proprietary additions and modifications of Openfire.",
    content: {
        contact: {
            subheading: "Contact us",
            heading: "Feel free to get in touch with us.",
            description: "Whether you need help fixing your installation, looking for custom functionality to be implemented, want to discuss options for having a hosted real-time communication server, or just want to say hi - we're always happy to hear from you! You can drop us an email at <a href='mailto: info@goodbytes.nl'>info@goodbytes.nl</a> or use the form below.",
            submitButton: "Send"
        },
        stats: {
            subheading: "",
            heading: "Over 1000 commits to the Openfire project",
            description: "Experience is the most valuable thing we have to offer.",
            stats: [
                {
                    key: "of experience with Openfire development",
                    value: "10 year+",
                },
                {
                    key: "lines changed in the Openfire project",
                    value: "1M+",
                },
            ]
        }
    },
    services: [
        {
            name: "Installation",
            description: "Goodbytes Specializes in Openfire development and integration challenges, and provides installation, configuration, and maintenance services.",
            image: serverStatusImageSrc
        },
        {
            name: "Troubleshooting",
            description: "At times, something stopped working, and the person who had originally set things up has disappeared. If you're in a bind, please reach out.",
            image: serverTroubleshootingImageSrc
        },
        {
            name: "Custom features",
            description: "Our experienced Openfire developers can implement your custom or proprietary functionality for you.",
            image: serverFeaturesImageSrc
        },
        {
            name: "Upgrade & Migration",
            description: "We upgrade your Openfire from any old version to the current supported version, and we help deal with customization.",
            image: serverUpgradeImageSrc
        },
        {
            name: "Hosting",
            description: "Want to make use of the Openfire functionality, but not worry about hosting and maintenance? GoodBytes offers various hosting plans for feature-complete Openfire-based real-time community solutions.",
            image: serverRedundancyIllustrationImageSrc
        }
    ],
    social: {
        mastodon: "https://toot.igniterealtime.org/@guusdk",
        twitter: "https://www.twitter.com/guusdk",
        facebook: "https://www.facebook.com/guusderkinderen",
        linkedIn: "https://www.linkedin.com/in/guusderkinderen"
    },
    contact: {
        address: "Emeraldpad 28 5247 KG Rosmalen(NB)",
        phone: "06 5129 5587",
        email: "info@goodbytes.nl"
    }
}
