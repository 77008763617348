import React from "react";
import tw from "twin.macro";
import {css} from "styled-components/macro"; //eslint-disable-line

import Hero from "components/hero/TwoColumnWithPrimaryBackground";
import MainFeature from "components/features/TwoColWithButton";
import Footer from "components/footers/MiniCenteredFooter";
import ContactForm from "components/forms/TwoColContactUsWithIllustrationFullForm";
import Stats from "components/features/ThreeColCenteredStatsPrimaryBackground"
import data from "../data";

export default () => {

    const StyledDiv = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;

    const renderFeature = () => {
        return data.services.map((service, index) => {
            return <MainFeature
                key={service.name}
                subheading=""
                heading={service.name}
                description={service.description}
                imageSrc={service.image}
                buttonRounded={false}
                textOnLeft={index % 2 === 0}
            />
        });
    }

    return (
        <StyledDiv className="App">
            <Hero/>
            <section id="features">
                {renderFeature()}
            </section>
            <section id="stats">
                <Stats/>
            </section>
            <section id="contact">
                <ContactForm/>
            </section>
            <Footer/>
        </StyledDiv>
    );
}
