import React from "react";
import tw from "twin.macro";
import data from '../../data';
import styled from "styled-components";
import {Container as ContainerBase} from "components/misc/Layouts"
import {ReactComponent as FacebookIcon} from "../../images/facebook-icon.svg";
import {ReactComponent as TwitterIcon} from "../../images/twitter-icon.svg";
import {ReactComponent as LinkedInIcon} from "../../images/linkedin-icon.svg";
import {ReactComponent as MastodonIcon} from "../../images/mastodon-icon.svg";


const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-10 lg:py-16`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`

export default () => {
    return (
        <Container>
            <Content>
                <Row>
                    <LogoContainer>
                        <LogoText>{data.name}</LogoText>
                    </LogoContainer>
                    <LinksContainer>
                        <Link href="#hero">Home</Link>
                        <Link href="#features">Features</Link>
                        <Link href={`mailto:${data.contact.email}`}>Contact Us</Link>
                    </LinksContainer>
                    <SocialLinksContainer>
                        <SocialLink href={data.social.facebook}>
                            <FacebookIcon/>
                        </SocialLink>
                        <SocialLink rel="me" href={data.social.mastodon}>
                            <MastodonIcon/>
                        </SocialLink>
                        <SocialLink href={data.social.twitter}>
                            <TwitterIcon/>
                        </SocialLink>
                        <SocialLink href={data.social.linkedIn}>
                            <LinkedInIcon/>
                        </SocialLink>
                    </SocialLinksContainer>
                    <CopyrightText>
                        {data.contact.address} <br/>
                        &copy; Copyright {(new Date().getFullYear())}, {data.name}. All Rights Reserved.
                    </CopyrightText>
                </Row>
            </Content>
        </Container>
    );
};
