import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import swal from "sweetalert";

import {css} from "styled-components/macro"; //eslint-disable-line
import {SectionHeading, Subheading as SubheadingBase} from "components/misc/Headings";
//import {PrimaryButton as PrimaryButtonBase} from "components/misc/Buttons";
//import Loader from 'components/misc/Loader';
import EmailIllustrationSrc from "images/email-illustration.svg";
import data from '../../data';
import axios from '../../axios'

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
    tw`md:w-7/12 mt-16 md:mt-0`,
    props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
    `background-image: url("${props.imageSrc}");`,
    tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

//const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-full mx-auto md:mx-0`;
//const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 focus:border-primary-500`;
//const Textarea = styled(Input).attrs({as: "textarea"})`${tw`h-24`}`
//const SubmitButton = tw(PrimaryButtonBase)`bg-primary-900 inline-block mt-8`;

export default class TwoColContactUsWithIllustrationFullForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            errors: null,
            name: "",
            email: "",
            subject: "",
            message: ""
        }

        this.url = process.env.NODE_ENV !== 'production' ? 'http://localhost:7000' : 'http://mailer.goodbytes.nl';
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const name = e.target.name;
        this.setState({
            [name]: e.target.value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({loading: true});

        const handleError = (error) => {
            const response = error.response;

            if (response) {
                if (response.status === 422) {
                    const errors = error.response.data.errors;
                    swal("sorry", errors.join('\n\n '), "warning");
                } else {
                    console.error({error})
                    swal("sorry", "Something went wrong, please try again later !", "error");
                }
            } else {
                console.error({error})
                swal("sorry", "Something went wrong, please try again later !", "error");
            }
        }

        const handleSuccess = (response) => {
            swal("Success", "Thank you for contacting us, we will answer you as soon as we can.", "success");
            this.setState({name: "", email: "", subject: "", message: ""})
        }

        axios
            .post(this.url, {
                name: this.state.name,
                email: this.state.email,
                subject: this.state.subject,
                message: this.state.message
            })
            .then(response => handleSuccess(response))
            .catch(error => handleError(error))
            .finally(() => this.setState({loading: false}))
    }

    render() {
        return (
            <Container>
                <TwoColumn>
                    <ImageColumn>
                        <Image imageSrc={EmailIllustrationSrc}/>
                    </ImageColumn>
                    <TextColumn textOnLeft={true}>
                        <TextContent>
                            <Subheading>{data.content.contact.subheading}</Subheading>
                            <Heading>
                                Feel free to <span tw="text-primary-900">get in touch</span>
                                <wbr/> with us.
                            </Heading>
                            <Description>
                                Whether you need help fixing your installation, looking for custom functionality to
                                be implemented, want to
                                discuss options for having a hosted real-time communication server, or just want to say
                                hi - we're always happy to hear from you! You can drop us an email at <a
                                tw="text-primary-900" href='mailto:info@goodbytes.nl'>
                                info@goodbytes.nl</a>
                            </Description>
                           {/* <Container>
                                <Loader show={this.state.loading}/>
                                <Form action="#" method="post" onSubmit={this.handleSubmit}>
                                    <Input
                                        required
                                        type="email"
                                        name="email"
                                        placeholder="Your Email Address"
                                        onChange={this.handleChange}
                                        value={this.state.email}
                                    />
                                    <Input
                                        required
                                        minlength="3"
                                        type="text"
                                        name="name"
                                        placeholder="Full Name"
                                        onChange={this.handleChange}
                                        value={this.state.name}
                                    />
                                    <Input
                                        required
                                        minlength="5"
                                        type="text"
                                        name="subject"
                                        placeholder="Subject"
                                        onChange={this.handleChange}
                                        value={this.state.subject}
                                    />
                                    <Textarea
                                        required
                                        minlength="10"
                                        name="message"
                                        placeholder="Your Message Here"
                                        onChange={this.handleChange}
                                        value={this.state.message}
                                    />
                                    <SubmitButton type="submit">{data.content.contact.submitButton}</SubmitButton>
                                </Form>
                            </Container>*/}
                        </TextContent>
                    </TextColumn>
                </TwoColumn>
            </Container>
        );
    }
}
