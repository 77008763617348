import React from "react";
import tw from "twin.macro";
import {css} from "styled-components/macro"; //eslint-disable-line
import HeaderBase, {
    LogoLink as LogoLinkBase,
    NavLinks,
    NavLink as NavLinkBase,
    PrimaryLink as PrimaryLinkBase
} from "../headers/light";
import {Container as ContainerBase, ContentWithVerticalPadding, Content2Xl} from "components/misc/Layouts";
import {SectionHeading} from "components/misc/Headings";
import {SectionDescription} from "components/misc/Typography";
import {PrimaryButton as PrimaryButtonBase} from "components/misc/Buttons";
import logoImageSrc from "images/logo.png";
import serverIllustrationImageSrc from "images/server-illustration-2.svg";
import data from "../../data"

const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 bg-primary-900 text-white`;
const Header = tw(HeaderBase)`max-w-none -mt-8 py-8 -mx-8 px-8`;
const NavLink = tw(NavLinkBase)`lg:text-white lg:hocus:text-gray-100 lg:hocus:border-gray-100`;
const LogoLink = tw(LogoLinkBase)`text-white hocus:text-gray-100`;
const PrimaryLink = tw(PrimaryLinkBase)`shadow-raised lg:bg-primary-400 lg:hocus:bg-primary-500`;

const Container = tw(ContainerBase)``;
const Row = tw.div`flex items-center flex-col lg:flex-row`;
const Column = tw.div`lg:w-1/2`;
const TextColumn = tw.div`text-center lg:text-left`;
const IllustrationColumn = tw(Column)`mt-16 lg:mt-0 lg:ml-16`;
const Heading = tw(SectionHeading)`max-w-3xl lg:max-w-4xl lg:text-left leading-tight`;
const Description = tw(SectionDescription)`mt-4 max-w-2xl text-white lg:text-base mx-auto lg:mx-0`;
const PrimaryButton = tw(PrimaryButtonBase)`mt-8 text-sm sm:text-base px-6 py-5 sm:px-10 sm:py-5 bg-primary-400 inline-block hocus:bg-primary-500`;
const Image = tw.img`w-144 ml-auto`

export default () => {
    const logoLink = (
        <LogoLink href="/">
            <img src={logoImageSrc} alt="Logo"/>
            Goodbytes
        </LogoLink>
    );
    const navLinks = [
        <NavLinks key={1}>
            <NavLink href="#features">Features</NavLink>
            <PrimaryLink href="#contact">Contact</PrimaryLink>
        </NavLinks>
    ];
    return (
        <PrimaryBackgroundContainer>
            <Content2Xl>
                <Header logoLink={logoLink} links={navLinks}/>
                <Container>
                    <ContentWithVerticalPadding>
                        <Row>
                            <TextColumn>
                                <Heading>{data.leading}</Heading>
                                <Description>{data.description}</Description>
                                <Description>{data.introduction}</Description>
                                <PrimaryButton as="a" href={data.primaryButtonUrl}>
                                    {data.primaryButtonText}
                                </PrimaryButton>
                            </TextColumn>
                            <IllustrationColumn>
                                <Image src={serverIllustrationImageSrc} alt="main-banner"/>
                            </IllustrationColumn>
                        </Row>
                    </ContentWithVerticalPadding>
                </Container>
            </Content2Xl>
        </PrimaryBackgroundContainer>
    );
};
